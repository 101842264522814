import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable, TextInput } from 'react-native';
import { Primary, Secondary, color2, color1, Width } from '../../Utility/Utility';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import { LinearGradient } from 'expo-linear-gradient';
import { useTheme } from '../../Contexts/ThemeProvider';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import Spinner from 'react-native-loading-spinner-overlay';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const ChangePassword = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [isSpinner, setIsSpinner] = useState(false)
    const userId = useRef('')

    useEffect(() => {
        getUserDetail()
    }, [])

    const getUserDetail = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            userId.current = data.id
        } catch (e) {
            // error reading value
        }
    }

    const onChangePassword = () => {
        if (oldPassword == '') {
            Toast.show({
                type: 'error',
                text1: 'Please enter old password',
            });
        } else if (newPassword == '') {
            Toast.show({
                type: 'error',
                text1: 'Please enter new password',
            });
        } else if (confirmNewPassword == '') {
            Toast.show({
                type: 'error',
                text1: 'Please enter confirm new password',
            });
        } else if (newPassword != confirmNewPassword) {
            Toast.show({
                type: 'error',
                text1: 'New password and confirm new password does not match',
            });
        } else {
            setIsSpinner(true)
            axios.put(`${BASE_URL}/reset/password`, {
                id: userId.current,
                new_password: newPassword,
                old_password: oldPassword
            })
                .then((res) => {
                    setIsSpinner(false)
                    Toast.show({
                        type: 'success',
                        text1: res.data?.message,
                    });
                })
                .catch((e) => {
                    setIsSpinner(false)
                    if (e.response.status == 401) {
                        dispatch(resetAuth())
                    }
                })
        }
    }


    return (
        <>
            <Spinner
                visible={isSpinner}
                textContent={'Loading...'}
                textStyle={styles.spinnerTextStyle}
            />
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ marginTop: 5, height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Change Password</Text>
                    </View>
                </View>
                <View style={styles.mainView}>
                    <Text style={styles.label}>Old Password</Text>
                    <TextInput style={[styles.input, { backgroundColor: colors.color2, color: colors.Secondary }]} placeholder='Enter your old password' placeholderTextColor={'#D3D3D3'} onChangeText={text => setOldPassword(text)} value={oldPassword} />
                    <Text style={styles.label}>New Password</Text>
                    <TextInput style={[styles.input, { backgroundColor: colors.color2, color: colors.Secondary }]} placeholder='Enter your new password' placeholderTextColor={'#D3D3D3'} onChangeText={text => setNewPassword(text)} value={newPassword} />
                    <Text style={styles.label}>Confirm New Password</Text>
                    <TextInput style={[styles.input, { backgroundColor: colors.color2, color: colors.Secondary }]} placeholder='Enter your confirm new password' placeholderTextColor={'#D3D3D3'} onChangeText={text => setConfirmNewPassword(text)} value={confirmNewPassword} />
                    <Pressable onPress={() => onChangePassword()}>
                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#26D0CE', '#1A2980']} style={styles.saveButton}>
                            <Text style={styles.saveButtonText}>Save</Text>
                        </LinearGradient>
                    </Pressable>
                </View>
            </View>
        </>
    )
}

export default ChangePassword;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    headerTitle: {
        fontSize: 16,
    },
    titleView: {
        alignItems: 'center',
        width: '90%'
    },
    mainView: {
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        marginLeft: 40,
        marginTop: 20
    },
    label: {
        color: color1,
        fontSize: 15,
        fontFamily: 'Roboto-Regular',
        fontWeight: '600',
        marginTop: 17
    },
    input: {
        borderRadius: 12,
        width: Width * 0.8,
        marginTop: 15,
        paddingLeft: 15,
        height: 50
    },
    saveButton: {
        borderRadius: 12,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 30
    },
    saveButtonText: {
        color: Secondary,
        fontFamily: 'Roboto-Medium',
        fontSize: 20
    },
})