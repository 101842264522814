export const lightColors = {
    Primary: '#FFFFFF',
    Secondary: '#000000',
    color1: '#787878',
    color2: '#d3d3d3',
    color3: '#0033FF'
}

export const darkColors = {
    Primary: '#000000',
    Secondary: '#FFFFFF',
    color1: '#787878',
    color2: '#404040',
    color3: '#0033FF'
}