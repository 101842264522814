import { SET_LOGIN_SUCCESS, SET_USER, RESET_AUTH } from "./Types"

export const login = (payload, props) => {
    return async (dispatch) => {

    }
}

export const setUser = (user) => ({ type: SET_USER, payload: user })

export const setLoginSuccess = () => ({ type: SET_LOGIN_SUCCESS })

export const resetAuth = () => ({ type: RESET_AUTH })